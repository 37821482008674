import React, { useEffect, useState } from "react";
import yourImage from "../Assets/images/lappy.png";
import { BsStars } from "react-icons/bs";
import { PiPhoneCallFill } from "react-icons/pi";
import shape3 from "../Assets/images/shape3.webp";
import star from "../Assets/images/Star.webp";
import { useNavigate } from "react-router-dom";

const sentences = [
  " Empower Your World: ",
  "Unify, Simplify,",
  " Achieve with Our",
  " New System.",
];

const Header = () => {
  const [index, setIndex] = useState(0);
  const [subIndex, setSubIndex] = useState(0);
  const [text, setText] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (index >= sentences.length) {
      return;
    }

    if (subIndex === sentences[index].length + 1) {
      const timeout = setTimeout(() => {
        setIndex(index + 1);
        setSubIndex(0);
      }, 2000);

      return () => clearTimeout(timeout);
    }

    const timeout = setTimeout(() => {
      setSubIndex((prev) => prev + 1);
    }, Math.max(150, parseInt(Math.random() * 350)));

    return () => clearTimeout(timeout);
  }, [subIndex, index]);

  useEffect(() => {
    if (index < sentences.length) {
      setText((prevText) => prevText + sentences[index]?.charAt(subIndex));
    }
  }, [subIndex, index]);

  return (
    <React.Fragment>
      <div className="custom-gradient w-full px-5 md:px-10 py-10 relative">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="w-full tracking-wider mb-10 md:mb-0">
            <div className="text-md font-medium text-primaryColor">
              Welcome to i-Ber
            </div>

            <div className="text-3xl md:text-5xl font-extrabold text-primaryTextColor leading-tight md:leading-snug typing">
              {text}
            </div>

            <div className="mt-2 text-base md:text-lg text-[#A9A8C0]">
              Streamline your business operations with our all-in-one POS
              management software.
            </div>

            <div className="flex flex-col md:flex-row items-center mt-4 space-y-4 md:space-y-0 md:space-x-4">
              <button className="cursor-pointer tracking-wider h-12 w-full md:w-2/5 flex justify-center items-center text-lg text-white bg-primaryColor hover:bg-primaryHoverColor transition ease-in-out hover:duration-300 rounded-xl shadow-lg transform flip-on-hover">
                <div className="flip-on-hover-text">
                  <BsStars color="#fff" size={22} />
                  &nbsp;Book Free Demo
                </div>
              </button>

              <button
              onClick={() => navigate('/contact')}
              className="tracking-wider h-12 w-full md:w-2/5 flex content-center items-center justify-center text-lg bg-transparent ring-2 hover:ring-primaryColor ring-primaryTextColor  hover:text-primaryColor text-primaryTextColor transition ease-in-out duration-300 rounded-xl shadow-lg">
                <PiPhoneCallFill size={22} />
                &nbsp;Contact Us
              </button>
            </div>
          </div>

          <div className="w-full relative">
            <img
              className="h-auto w-full pulse-HeaderImg"
              src={yourImage}
              alt="salon-pic"
            />

            <img
              src={shape3}
              alt="Floating Image"
              className="absolute top-0 left-0 w-12 h-12 custom-circularMotion"
            />
          </div>
        </div>

        <img
          src={star}
          alt="Floating Image"
          className="absolute bottom-0 left-1/2 transform -translate-x-1/2 floatBottomToTop w-24 h-24"
        />

        {/* <div className="star-moving absolute top-12 left-[44%] transform -translate-x-1/2 -translate-y-1/2">
                    <svg className="star-svg" viewBox="0 0 24 24" width="30" height="30" fill="yellow">
                        <path d="M12 .587l3.515 7.12 7.97 1.158-5.754 5.609 1.357 7.91L12 18.896l-7.088 3.488 1.357-7.91-5.754-5.609 7.97-1.158z" />
                    </svg>
                </div> */}
      </div>
    </React.Fragment>
  );
};

export default Header;
