import React from "react";
import advanceFeatures from "../Assets/images/advanceFeatures.webp";
import cyber_security from "../Assets/images/security.png";
import RetinaReady from "../Assets/images/RetinaReady.webp";
import analysis from "../Assets/images/analysis.png";
import lappyPhoneFrame from "../Assets/images/lappyPhoneFrame.webp";

const FeaturesProof = () => {
  const cards = [
    {
      imageUrl: advanceFeatures,
      name: "Comprehensive Integration",
      description:
        "Manage all aspects of your business, including employee management, appointments, inventory, and more, within a single platform.Seamlessly integrate multiple features to streamline your operations and reduce manual tasks.",
    },
    {
      imageUrl: cyber_security,
      name: "Advanced Security Protocols",
      description:
        "Protect your sensitive business data with robust security features that safeguard against unauthorized access.Our software is built with the latest encryption and security measures to ensure your peace of mind.",
    },
    {
      imageUrl: RetinaReady,
      name: "User-Friendly Interface",
      description:
        "Designed with simplicity in mind, our software is easy to navigate, making it accessible for users of all skill levels.Enjoy a smooth experience with intuitive controls that minimize the learning curve.",
    },
    {
      imageUrl: analysis,
      name: "Real-Time Analytics and Reporting",
      description:
        "Gain valuable insights into your business performance with detailed analytics and customizable reports.Make informed decisions with real-time data that helps you track trends, sales, and more.",
    },
  ];

  return (
    <React.Fragment>
      <div className="tracking-wide mt-6 text-center text-3xl md:text-4xl font-bold text-primaryTextColor leading-tight md:leading-snug">
        I-Ber Providing You <br />
        <span className="text-primaryColor animate-pulse">Best</span> Best
        Features
      </div>

      <div className="tracking-wide text-center mt-2 text-base md:text-lg text-[#A9A8C0]">
        Our POS management software offers a robust, all-in-one solution for
        your business needs,
        <br /> combining ease of use with advanced features. From secure
        transactions to real-time analytics, it’s designed to streamline
        operations and enhance efficiency.
      </div>

      <div className="mx-10 py-8">
        <div className="flex flex-wrap">
          <div className="w-full md:w-1/3 px-4 mb-4 md:mb-0">
            {cards.slice(0, 2).map((card, index) => (
              <div key={index} className="mb-4">
                <div className="flex justify-end">
                  <img
                    src={card.imageUrl}
                    alt={card.name}
                    className="w-16 h-16 object-cover rounded-lg pulse-img"
                  />
                </div>

                <h3 className="text-primaryTextColor font-bold text-xl mt-2 text-end">
                  {card.name}
                </h3>

                <p className="text-[#A9A8C0] text-base text-end">
                  {card.description}
                </p>
              </div>
            ))}
          </div>

          <div className="w-full md:w-1/3 px-4">
            <img
              src={lappyPhoneFrame}
              alt="lappyPhoneFrame"
              className="w-full h-auto object-cover rounded-lg"
            />
          </div>

          <div className="w-full md:w-1/3 px-4">
            {cards.slice(2, 4).map((card, index) => (
              <div key={index} className="mb-4">
                <img
                  src={card.imageUrl}
                  alt={card.name}
                  className="w-16 h-16 object-cover rounded-lg pulse-img"
                />

                <h3 className="text-primaryTextColor font-bold text-xl mt-2">
                  {card.name}
                </h3>

                <p className="text-[#A9A8C0] text-base">{card.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FeaturesProof;
