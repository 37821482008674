import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Layout from './components/Layout';
import AboutPage from './pages/about';
import ContactPage from './pages/contact';
import Home from './pages/home';
import Services from './pages/services';
import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
    <Router>
      <Layout>
      <ScrollToTop />
        <Routes>
          <Route path='/' element={<Home />}></Route>
          <Route path='/home' element={<Home />}></Route>
          <Route path='/about' element={<AboutPage />}></Route>
          <Route path='/contact' element={<ContactPage />}></Route>
          <Route path='/services' element={<Services />}></Route>
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
