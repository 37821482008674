import React, { useState } from 'react';
import { Modal, Box } from '@mui/material';
import { TfiEmail } from 'react-icons/tfi';
import { PiUserLight } from 'react-icons/pi';
import { MdOutlinePhone } from 'react-icons/md';
import { HiOutlineBuildingOffice } from 'react-icons/hi2';
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: '12px',
    overflowY: 'auto',
    maxHeight: '70%',
};


function ContactFormModal({ open, setOpen }) {

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <div>
            <Modal open={open} onClose={handleClose}>
                <Box className='shadow-lg p-4 rounded-md md:w-[45%] w-[70%] no-scrollbar' sx={style}>
                    <form>
                        <h1 className='flex justify-center text-xl font-semibold mb-2'>
                            Company Detail
                        </h1>
                        <div className='relative w-full mb-3 px-3'>
                            <div className="text-[#132c4a] block text-md font-bold mb-2">Your Name</div>
                            <input
                                type="text"
                                id="name"
                                autoComplete='off'
                                className="text-[#132c4a] border-0 pl-14 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150 font-semibold"
                                placeholder="Your Name"
                            />
                            <span className='absolute top-11 left-8'><PiUserLight /></span>
                        </div>
                        <div className='relative w-full mb-3 px-3'>
                            <div className="text-[#132c4a] block text-md font-bold mb-2">Company Name</div>
                            <input
                                type="text"
                                id="company name"
                                autoComplete='off'
                                className="text-[#132c4a] border-0 pl-14 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150 font-semibold"
                                placeholder="Company Name"
                            />
                            <span className='absolute top-11 left-8'><HiOutlineBuildingOffice /></span>
                        </div>
                        <div className='relative w-full mb-3 px-3'>
                            <div className="text-[#132c4a] block text-md font-bold mb-2">Your Phone</div>
                            <input
                                type="text"
                                id="phone"
                                autoComplete='off'
                                className="text-[#132c4a] border-0 pl-14 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150 font-semibold"
                                placeholder="Phone Number"
                            />
                            <span className='absolute top-11 left-8'><MdOutlinePhone /></span>
                        </div>
                        <div className='relative w-full mb-3 px-3'>
                            <div className="text-[#132c4a] block text-md font-bold mb-2">Email Address</div>
                            <input
                                type="email"
                                id="email"
                                autoComplete='off'
                                className="text-[#132c4a] border-0 pl-14 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150 font-semibold"
                                placeholder="Enter email address"
                            />
                            <span className='absolute top-11 left-8'><TfiEmail /></span>
                        </div>
                        <div className='relative w-full mb-3 px-3'>
                            <div className="text-[#132c4a] block text-md font-bold mb-2">Message</div>
                            <textarea
                                type="text"
                                id="message"
                                className="text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150 font-semibold"
                                placeholder="Write Message"
                            />
                        </div>
                        <div className='relative w-full mb-3 px-3'>
                            <button onClick={handleClose} className='text-white bg-primaryColor hover:bg-primaryHoverColor p-2 font-semibold rounded-md shadow-md'>
                                Send Message
                            </button>
                        </div>
                    </form>
                </Box>
            </Modal>
        </div>
    );
}

export default ContactFormModal;
