import React, { useEffect } from "react";
import abtPic from "../Assets/images/abtBanner.webp";
import { BsStarFill } from "react-icons/bs";
import abt1 from "../Assets/images/abt1.webp";
import abt2 from "../Assets/images/abt2.webp";
import abt3 from "../Assets/images/abt3.webp";
import abt4 from "../Assets/images/abt4.webp";
import ceo from "../Assets/images/ceo.png";
import cto from "../Assets/images/cto.png";
import { MdCheckBox } from "react-icons/md";

const AboutPage = () => {
  useEffect(() => {
    document.title = "i-Ber | About";
  }, []);

  return (
    <React.Fragment>
      <div className="relative">
        <img src={abtPic} alt="Banner" className="w-full h-96 object-cover" />

        {/* Floating Stars */}
        <BsStarFill
          className="abtStar"
          size={30}
          style={{ top: "10%", left: "20%" }}
        />
        <BsStarFill
          className="abtStar"
          size={30}
          style={{ top: "70%", right: "15%" }}
        />
        <BsStarFill
          className="abtStar"
          size={30}
          style={{ bottom: "20%", left: "30%" }}
        />
        <BsStarFill
          className="abtStar"
          size={30}
          style={{ bottom: "15%", right: "25%" }}
        />

        <div
          className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <h1 className="text-white text-5xl font-bold pulse-HeaderImg">
            All About Us
          </h1>
        </div>
      </div>

      <div className="tracking-wide mt-5 text-center text-3xl md:text-4xl font-bold text-primaryTextColor leading-tight md:leading-snug">
        Our <span className="text-primaryColor animate-pulse">Story</span>
      </div>

      <div className="flex flex-col md:flex-row w-full justify-center items-center md:gap-10 mt-5 bg-[#f7fffe] py-8 px-4 sm:px-6 md:px-10 text-white">
        {/* Image Section */}
        <div data-aos="fade-right" className="md:w-1/2 w-full mb-6 md:mb-0">
          <img
            className="w-full h-auto rounded-lg"
            src="https://c4.wallpaperflare.com/wallpaper/72/379/768/technology-security-multimedia-electronic-device-wallpaper-preview.jpg"
            alt="img"
          />
        </div>

        {/* Text Section */}
        <div data-aos="fade-left" className="flex w-full md:w-1/2">
          <div>
            <div
              style={{ fontFamily: "cursive" }}
              className="text-primaryColor text-lg md:text-xl tracking-wide"
            >
              About Us
            </div>

            <div className="text-primaryTextColor text-2xl md:text-3xl font-bold mt-2 tracking-wide leading-normal">
              Streamline Retail Operations with I-Ber's All-in-One POS Management System
            </div>

            <div className="text-gray-600 mt-4 tracking-wide text-justify">
              Founded in 2023, I-Ber has quickly become a trusted name in POS management, partnering with over 500 stores to deliver cutting-edge, seamless solutions. Our POS software integrates a wide range of essential features, including employee management, appointment scheduling, inventory control, laser technology, analytics, invoicing, expenditure tracking, product management, and membership management. Designed for efficiency and ease of use, I-Ber empowers businesses to streamline operations and enhance customer experiences.
            </div>

            <div className="flex flex-wrap items-center mt-4 gap-4 md:gap-6 text-primaryTextColor">
              <div className="flex items-center gap-2">
                <MdCheckBox size={20} color="#fe8740" /> <span>Innovative</span>
              </div>
              <div className="flex items-center gap-2">
                <MdCheckBox size={20} color="#fe8740" /> <span>Empowering</span>
              </div>
              <div className="flex items-center gap-2">
                <MdCheckBox size={20} color="#fe8740" /> <span>Reliable</span>
              </div>
              <div className="flex items-center gap-2">
                <MdCheckBox size={20} color="#fe8740" /> <span>Seamless</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-6xl mx-auto grid grid-cols-1 sm:grid-cols-2 gap-10 my-5 px-4 md:px-6 lg:px-8">
        <div className="bg-white shadow-lg rounded-lg overflow-hidden flex flex-col">
          <img
            src={ceo}
            alt="CEO"
            className="object-cover w-full h-[300px] sm:h-[350px] md:h-[400px]"
          />
          <div className="p-4 flex-grow">
            <h2 className="text-xl md:text-2xl font-bold text-gray-800">
              Peeyush Jena
            </h2>
            <p className="text-md md:text-lg text-gray-600">
              Chief Operation Officer
            </p>
            <p className="text-md md:text-lg font-semibold text-blue-600">I-Ber</p>
          </div>
        </div>

        <div className="bg-white shadow-lg rounded-lg overflow-hidden flex flex-col">
          <img
            src={cto}
            alt="CTO"
            className="object-cover w-full h-[300px] sm:h-[350px] md:h-[400px]"
          />
          <div className="p-4 flex-grow">
            <h2 className="text-xl md:text-2xl font-bold text-gray-800">
              Ramesh Ch. Mohapatra
            </h2>
            <p className="text-md md:text-lg text-gray-600">
              Chief Technical Officer
            </p>
            <p className="text-md md:text-lg font-semibold text-blue-600">I-Ber</p>
          </div>
        </div>
      </div>

      <div
        style={{ fontFamily: "cursive" }}
        className="text-primaryColor text-lg md:text-xl tracking-wide text-center mt-6"
      >
        Features
      </div>

      <div className="text-center text-primaryTextColor text-2xl md:text-3xl font-bold mt-2 tracking-wide leading-normal">
        What Makes Us Unique?
      </div>

      <div className="flex flex-wrap gap-4 px-10 justify-between my-6">
        <img
          src="https://poslinksolution.com/wp-content/uploads/2024/01/How-To-Use-POS-Machine.webp"
          alt="features-img"
          className="w-full md:w-[30%] h-auto object-cover rounded-md"
        />
        <img
          src="https://t3.ftcdn.net/jpg/01/63/13/16/360_F_163131613_n0ZXuSkxVBsLuHl19JV9we7Eq3C4gMnK.jpg"
          alt="features-img"
          className="w-full md:w-[30%] h-auto object-cover rounded-md"
        />
        <img
          src='https://wallpapercave.com/wp/wp8284208.png'
          alt="features-img"
          className="w-full md:w-[30%] h-auto object-cover rounded-md"
        />

        <img
          src="https://bimpos.com/sites/default/files/images/posts/1fb76122-eb01-4731-a90a-ca3ee32b6bf0.jpeg"
          alt="features-img"
          className="w-full md:w-[30%] h-auto object-cover rounded-md"
        />
        <img
          src="https://store.bpapos.com/images/thumbs/0000008_elite-ii-restaurant-point-of-sale-system.jpeg"
          alt="features-img"
          className="w-full md:w-[30%] h-auto object-cover rounded-md"
        />
        <img
          src='https://restaurant.eatapp.co/hs-fs/hubfs/POS2-1.webp?width=782&height=519&name=POS2-1.webp'
          alt="features-img"
          className="w-full md:w-[30%] h-auto object-cover rounded-md"
        />

        <img
          src="https://www.chefstore.com/images/imagebank/blog/header_toast_pos_system.jpg"
          alt="features-img"
          className="w-full md:w-[30%] h-auto object-cover rounded-md"
        />
        <img
          src="https://assets.nerdwallet.com/blog/wp-content/uploads/2021/08/GettyImages-1003743930.jpg"
          alt="features-img"
          className="w-full md:w-[30%] h-auto object-cover rounded-md"
        />
        <img
          src='https://res.cloudinary.com/peter-white/image/upload/v1666681400/large_Restaurant_Types07_3abf9bd668_a6c7a09b6a.png'
          alt="features-img"
          className="w-full md:w-[30%] h-auto object-cover rounded-md"
        />
      </div>

      {/* <div className="mx-auto px-10 mt-6 bg-[#EBF8FF] py-5">
        <div className="flex flex-col md:flex-row items-center justify-between gap-6">
          <div className="w-full md:w-1/2">
            <img
              src={abt4}
              alt="Description"
              className="max-w-full h-auto rounded-lg shadow-lg"
            />
          </div>

          <div className="w-full md:w-1/2">
            <h2 className="text-2xl font-bold">Sarah Johnson</h2>
            <div>(CEO)</div>
            <p className="text-gray-600 mt-2">
              Meet Sarah, a visionary hairstylist with a passion for beauty and
              a heart full of dreams. As the owner of Serene Beauty, her journey
              unfolds in the enchanting world of tranquil transformations.
            </p>

            <div className="w-full md:w-1/2 space-y-4">
              <div className="mt-8">
                <div className="flex items-center">
                  <div className="rounded-full bg-primaryColor h-12 w-12 min-w-12 min-h-12 flex items-center justify-center text-white font-bold mr-4">
                    1
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold mb-2">Heading 1</h3>
                    <p className="text-gray-600">
                      Description for heading 1. Lorem ipsum dolor sit amet,
                      consectetur adipiscing elit.
                    </p>
                  </div>
                </div>
              </div>

              <div className="mt-8">
                <div className="flex items-center">
                  <div className="rounded-full bg-primaryColor h-12 w-12 min-w-12 min-h-12 flex items-center justify-center text-white font-bold mr-4">
                    2
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold mb-2">Heading 2</h3>
                    <p className="text-gray-600">
                      Description for heading 2. Duis aute irure dolor in
                      reprehenderit in voluptate velit esse cillum.
                    </p>
                  </div>
                </div>
              </div>

              <div className="mt-8">
                <div className="flex items-center">
                  <div className="rounded-full bg-primaryColor h-12 w-12 min-w-12 min-h-12 flex items-center justify-center text-white font-bold mr-4">
                    3
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold mb-2">Heading 3</h3>
                    <p className="text-gray-600">
                      Description for heading 3. Sed do eiusmod tempor
                      incididunt ut labore et dolore magna aliqua.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </React.Fragment>
  );
};

export default AboutPage;
