import React, { useEffect, useState } from 'react';
import frameImg from '../Assets/images/Frame1.webp';
import { CgArrowLongRight } from "react-icons/cg";
import { useNavigate } from 'react-router-dom';
const sentences = [
    " A Complete Software ",
    "solution for your Business Needs."
];

const BusinessNeeds = () => {
    const [index, setIndex] = useState(0);
    const [subIndex, setSubIndex] = useState(0);
    const [text, setText] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        if (index >= sentences.length) {
            return;
        }

        if (subIndex === sentences[index].length + 1) {
            const timeout = setTimeout(() => {
                setIndex(index + 1);
                setSubIndex(0);
            }, 2000);

            return () => clearTimeout(timeout);
        }

        const timeout = setTimeout(() => {
            setSubIndex((prev) => prev + 1);
        }, Math.max(150, parseInt(Math.random() * 350)));

        return () => clearTimeout(timeout);
    }, [subIndex, index]);

    useEffect(() => {
        if (index < sentences.length) {
            setText((prevText) => prevText + sentences[index]?.charAt(subIndex));
        }
    }, [subIndex, index]);

    return (
        <React.Fragment>
            <div className="w-full px-5 md:px-10 pt-10 relative">
                <div className='flex flex-col md:flex-row justify-between items-center'>
                    <div className='w-full tracking-wider mb-10 md:mb-0'>
                        <div className="animate-pulse text-3xl md:text-5xl font-extrabold text-primaryTextColor leading-tight md:leading-snug">
                            {text}
                        </div>

                        <div className='mt-2 text-base md:text-lg text-[#A9A8C0]'>
                            Optimizing Business Processes<br /> with Comprehensive Software Systems.
                        </div>

                        <div
                        onClick={() =>navigate('/services')}
                        className="flex items-center mt-2 text-md font-medium text-primaryColor">
                            Explore more &nbsp;
                            <div className="cursor-pointer p-2 rounded-full bg-transparent hover:bg-primaryColor text-primaryColor hover:text-white transition duration-300 ease-in-out transform hover:scale-110">
                                <CgArrowLongRight size={28} />
                            </div>
                        </div>
                    </div>

                    <div className='w-full flex justify-end'>
                        <img
                            className='h-auto w-full'
                            src={frameImg}
                            alt="salon-pic" />
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default BusinessNeeds
