import React from "react";
import settings from "../Assets/images/stng.webp";
import functionality from "../Assets/images/functionality.webp";
import protocol from "../Assets/images/prtcl.webp";
import supporting from "../Assets/images/sprtng.webp";

const Features = () => {
  const cardData = [
    {
      imageUrl: settings,
      title: "Easy To Use",
      description: "Effortlessly manage your business with our intuitive and easy-to-use POS software, designed for seamless operation and maximum efficiency..",
      gradient: "bg-gradient-to-t from-[#ffe7dd] to-[#ffffff]",
    },
    {
      imageUrl: functionality,
      title: "Fully Functional",
      description: "Experience complete business control with our fully functional POS software, offering all the tools you need for smooth and efficient operations.",
      gradient: "bg-gradient-to-t from-[#f5fbff] to-[#ffffff]",
    },
    {
      imageUrl: protocol,
      title: "Secured Protocol",
      description: "Protect your business with our POS software, built with advanced security protocols to ensure safe and reliable operations.",
      gradient: "bg-gradient-to-t from-[#ffe7dd] to-[#ffffff]",
    },
    {
      imageUrl: supporting,
      title: "Suporting",
      description: " Rely on our POS software for ongoing support, ensuring smooth operations and assistance whenever you need it.",
      gradient: "bg-gradient-to-t from-[#f5fbff] to-[#ffffff]",
    },
  ];

  return (
    <React.Fragment>
      <div className="tracking-wide mt-6 text-center text-3xl md:text-4xl font-bold text-primaryTextColor leading-tight md:leading-snug">
        Features that make{" "}
        <span className="text-primaryColor animate-pulse">I-BER</span> special
      </div>

      <div className="tracking-wide text-center mt-2 text-base md:text-lg text-[#A9A8C0]">
        Streamline your business operations with our all-in-one POS management
        software.
      </div>

      <div className="mt-4 flex flex-wrap justify-center items-center gap-8 px-10 w-full">
        {cardData.map((card, index) => (
          <div
            key={index}
            className={`w-60 h-auto md:h-[360px] flex flex-col rounded-lg py-4 justify-between leading-normal bg-white shadow-lg ${card.gradient}`}
          >
            <div className="flex justify-center items-center">
              <img
                src={card.imageUrl}
                alt="features-img"
                className="w-24 h-24 md:w-32 md:h-32 object-cover rounded-full pulse-img"
              />
            </div>

            <div className="mt-2 text-center tracking-wide">
              <div className="text-primaryTextColor font-bold text-xl mb-2">
                {card.title}
              </div>

              <p className="text-[#A9A8C0] text-base px-4">
                {card.description}
              </p>
            </div>
          </div>
        ))}
      </div>

      {/* <div className="mt-4 flex flex-wrap justify-center items-center gap-8 px-10 w-full">
                {cardData.map((card, index) => (
                    <div key={index} className={`w-60 h-auto flex flex-col rounded-lg py-4 justify-between leading-normal bg-white shadow-lg ${card.gradient}`}>
                        <div className='flex justify-center items-center'>
                            <img src={card.imageUrl}
                                alt="features-img"
                                className="w-24 h-24 md:w-32 md:h-32 object-cover rounded-full" />
                        </div>

                        <div className="mt-2 text-center">
                            <div className="text-primaryTextColor font-bold text-xl mb-2">
                                {card.title}
                            </div>

                            <p className="text-gray-700 text-base px-4">
                                {card.description}
                            </p>
                        </div>
                    </div>
                ))}
            </div> */}
    </React.Fragment>
  );
};

export default Features;
